<style lang="scss">
.form-body {
  .card-footer {
    z-index: 9;
  }
}
#email-smtp-config {
  @mixin can-toggle-branding(
    $can-toggle-off-color: #4c4c4c,
    $can-toggle-on-color: #4c4c4c,
    $can-toggle-inactive-text: rgba(#fff, 0.5),
    $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
  ) {
    input[type="checkbox"] {
      &[disabled] ~ label {
        color: rgba($can-toggle-off-color, 0.5);
      }

      &:focus ~ label,
      &:hover ~ label {
        .can-toggle__switch {
          background-color: $can-toggle-off-color;
          &:after {
            color: darken($can-toggle-off-color, 10%);
          }
        }
      }
      &:hover ~ label {
        color: darken($can-toggle-off-color, 5%);
      }
      &:checked {
        ~ label {
          &:hover {
            color: darken($can-toggle-on-color, 3%);
          }

          .can-toggle__switch {
            background-color: lighten($can-toggle-on-color, 5%);
            &:after {
              color: darken($can-toggle-on-color, 5%);
            }
          }
        }

        &:focus,
        &:hover {
          ~ label {
            .can-toggle__switch {
              background-color: $can-toggle-on-color;
              &:after {
                color: darken($can-toggle-on-color, 10%);
              }
            }
          }
        }
      }
    }

    label {
      .can-toggle__label-text {
        flex: 1;
      }

      .can-toggle__switch {
        transition: background-color 0.3s $can-toggle-transition;
        background: lighten($can-toggle-off-color, 5%);
        &:before {
          color: $can-toggle-inactive-text;
        }
        &:after {
          // Autoprefixer choked here, so making the prefixes explicit
          -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
          transition: transform 0.3s $can-toggle-transition;
          color: $can-toggle-off-color;
        }
      }
    }
  }

  @mixin can-toggle-appearance(
    $can-toggle-width: 134px,
    $can-toggle-height: 36px,
    $can-toggle-border-radius: 4px,
    $can-toggle-offset: 2px,
    $can-toggle-label-font-size: 14px,
    $can-toggle-switch-font-size: 12px,
    $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
  ) {
    $can-toggle-switch-width: $can-toggle-width/2;

    input[type="checkbox"] {
      &:focus ~ label,
      &:hover ~ label {
        .can-toggle__switch {
          &:after {
            box-shadow: $can-toggle-shadow;
          }
        }
      }

      &:checked {
        ~ label {
          .can-toggle__switch {
            &:after {
              transform: translate3d(
                $can-toggle-width -
                  ($can-toggle-switch-width + $can-toggle-offset),
                0,
                0
              );
            }
          }
        }
        &:focus,
        &:hover {
          ~ label {
            .can-toggle__switch {
              &:after {
                box-shadow: $can-toggle-shadow;
              }
            }
          }
        }
      }
    }

    label {
      font-size: $can-toggle-label-font-size;

      .can-toggle__switch {
        height: $can-toggle-height;
        flex: 0 0 $can-toggle-width;
        border-radius: $can-toggle-border-radius;

        &:before {
          left: $can-toggle-width/2;
          font-size: $can-toggle-switch-font-size;
          line-height: $can-toggle-height;
          width: $can-toggle-width/2;
          padding: 0 12px;
        }

        &:after {
          top: $can-toggle-offset;
          left: $can-toggle-offset;
          border-radius: $can-toggle-border-radius/2;
          width: $can-toggle-switch-width - $can-toggle-offset;
          line-height: $can-toggle-height - ($can-toggle-offset * 2);
          font-size: $can-toggle-switch-font-size;
        }

        &:hover {
          &:after {
            box-shadow: $can-toggle-shadow;
          }
        }
      }
    }
  }

  .can-toggle {
    position: relative;
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }
    //overflow: hidden;

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;

      &[disabled] ~ label {
        pointer-events: none;
        .can-toggle__switch {
          opacity: 0.4;
        }
      }

      &:checked {
        ~ label {
          .can-toggle__switch {
            &:before {
              content: attr(data-unchecked);
              left: 0;
            }

            &:after {
              content: attr(data-checked);
            }
          }
        }

        &:focus,
        &:hover {
          ~ label {
          }
        }
      }
    }

    label {
      user-select: none;
      position: relative;
      display: flex;
      align-items: center;

      .can-toggle__label-text {
        flex: 1;
        padding-left: 32px;
      }

      .can-toggle__switch {
        position: relative;

        &:before {
          content: attr(data-checked);
          position: absolute;
          top: 0;
          text-transform: uppercase;
          text-align: center;
        }

        &:after {
          content: attr(data-unchecked);
          position: absolute;
          z-index: 5;
          text-transform: uppercase;
          text-align: center;
          background: white;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    // Default values for .can-toggle class
    @include can-toggle-branding;
    @include can-toggle-appearance;

    // Create toggles of different sizes by overriding the can-toggle-appearance mixin defaults
    &.can-toggle--size-small {
      @include can-toggle-appearance(
        90px,
        // Toggle width
          28px,
        // Toggle height
          2px,
        // Toggle border radius
          1px,
        // Offset (distance btw switch and box)
          13px,
        // Label font size
          10px,
        // Switch font size
          0 2px 2px rgba(black, 0.4) / Switch shadow on hover/focus
      );
    }
  }
}
</style>
<template>
  <div class="row" id="email-smtp-config">
    <div class="full-load" v-if="processing">
      <h1>
        Please Wait... <br />
        Sending test email
      </h1>
    </div>
    <div class="col-md-6 offset-3">
      <div class="card form-body">
        <div class="card-body">
          <legend><h5>Configure Outgoing Mail (SMTP)</h5></legend>
          <hr />
          <form>
            <div class="form-group">
              <label for="">Server <span class="text-danger">*</span></label>
              <input
                type="text"
                name=""
                class="form-control"
                id="server"
                v-model="server"
                :class="errors['server'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['server']" class="text-danger">{{
                errors["server"][0]
              }}</span>
            </div>

            <div class="form-group">
              <label for="">Port <span class="text-danger">*</span></label>
              <input
                type="number"
                name=""
                class="form-control"
                id="port"
                v-model="port"
                :class="errors['port'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['port']" class="text-danger">{{
                errors["port"][0]
              }}</span>
            </div>

            <div class="form-group">
              <label for="">Username <span class="text-danger">*</span></label>
              <input
                type="email"
                name=""
                class="form-control"
                id="username"
                v-model="username"
                :class="errors['username'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['username']" class="text-danger">{{
                errors["username"][0]
              }}</span>
            </div>

            <div class="form-group mb-1">
              <label for="">Password <span class="text-danger">*</span></label>
              <div class="input-group">
                <input
                  :type="password_type"
                  name=""
                  class="form-control"
                  id="password"
                  v-model="password"
                  :class="errors['password'] ? 'border border-danger' : ''"
                />
                <div
                  class="input-group-append"
                  style="cursor: pointer"
                  @click="showPassword()"
                >
                  <span class="input-group-text">
                    <!-- <i class="fa fa-eye"></i> -->
                    <i
                      :class="
                        password_type == 'password'
                          ? 'fa fa-eye'
                          : 'fa fa-eye-slash'
                      "
                    ></i>
                  </span>
                </div>
              </div>

              <span v-if="errors['password']" class="text-danger">{{
                errors["password"][0]
              }}</span>
            </div>
            <div class="row mt-4">
              <div class="col-md-12">
                <label for="">Connect with SSL/TLS</label>
              </div>
              <div class="col-md-12">
                <div class="can-toggle">
                  <input id="a" type="checkbox" v-model="ssl_tls" />
                  <label for="a">
                    <div
                      class="can-toggle__switch"
                      data-checked="TLS"
                      data-unchecked="SSL"
                    ></div>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-warning"
            style="float: left"
            @click="testConnection()"
            ref="kt_test_smtp_email_connection"
          >
            <i class="fa fa-envelope"></i>Send Test Email
          </button>
          <button
            class="btn btn-success"
            style="float: right"
            ref="kt_save_configure"
            @click="onSubmit()"
          >
            <i class="fa fa-check"></i>Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";

import {
  FETCH_SMTP_OUTGOING_EMAIL_CONFIGURE,
  STORE_SMTP_OUTGOING_EMAIL_CONFIGURE,
  CHECK_SMTP_CONNECTION
} from "@/core/services/store/actions.type";

export default {
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Setting",
          route: ""
        },
        {
          id: 3,
          title: "Configure Outgoing Mail (SMTP)",
          route: ""
        }
      ],

      errors: [],

      regEmail: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

      id: 0,
      //form-data
      username: "",
      server: "",
      port: 465,
      ssl_tls: 0,
      email: "",
      password: "",
      to_email: "",
      processing: false,

      password_type: "password"
    };
  },

  created() {
    if (this.$route.name == "admin.email.configure") {
      this.breadCrumbs[0].route = "admin.dashboard";
    } else {
      this.breadCrumbs[0].route = "client.dashboard";
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_SMTP_OUTGOING_EMAIL_CONFIGURE).then(data => {
      if (data.status == true) {
        this.id = data.data.id;
        this.username = data.data.username;
        this.password = data.data.password;
        this.server = data.data.server;
        this.port = data.data.port;
        this.ssl_tls = data.data.connect_with_ssl_tls;
      }
    });
  },
  methods: {
    showPassword() {
      if (this.password_type === "password") {
        this.password_type = "text";
      } else {
        this.password_type = "password";
      }
    },
    async testConnection() {
      const { value: email } = await this.$swal.fire({
        title: "Testing SMTP Email Connection",
        input: "email",
        inputPlaceholder: "Email address",
        confirmButtonText: "Send Email",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true,
        allowOutsideClick: false,
        showLoaderOnConfirm: true
      });

      var eMessaga = "";

      if (email) {
        this.processing = true;
        this.$store
          .dispatch(CHECK_SMTP_CONNECTION, {
            server: this.server,
            username: this.username,
            password: this.password,
            port: this.port,
            connect_with_ssl_tls: this.ssl_tls,
            to_email: email
          })
          .then(data => {
            this.processing = false;
            this.$toastr.s(data.msg);
          })
          .catch(err => {
            this.processing = false;

            if (err.msg.includes("Authentication Failed")) {
              eMessaga = "Username or Password is invalid";
            } else if (err.msg.includes("getaddrinfo failed")) {
              eMessaga = "Server is invalid";
            } else {
              eMessaga = err.msg;
            }
            this.$toastr.e(eMessaga);
          });
      }
    },

    onSubmit() {
      this.errors = [];
      const submitButton = this.$refs["kt_save_configure"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var data_ = {
        id_: this.id,
        server: this.server,
        username: this.username,
        password: this.password,
        port: this.port,
        connect_with_ssl_tls: this.ssl_tls
      };
      // this.loadingButton(ref);
      this.$store
        .dispatch(STORE_SMTP_OUTGOING_EMAIL_CONFIGURE, data_)
        .then(data => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          if (this.id == 0) {
            this.id = data.id;
          }
          this.$toastr.s(data.msg);
        })
        .catch(err => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.errors = err.error;
        });
    }
  }
};
</script>

<style></style>
